<template>
    <div>
        <template v-if="updateMode && !listMode">
            <v-btn
                :loading="loading.invoice"
                small
                class="ml-2 lighten-2"
                v-if="officeId == 1 && destinationId != 1 && salesTypeId != 5"
                @click.stop="printInvoice()"
            >
                <v-icon small>far fa-file-pdf</v-icon>
                <span class="ml-1">{{ $t('message.invoice') }}</span>
            </v-btn>
            <v-btn
                :loading="loading.invoice"
                small
                class="ml-2 lighten-2"
                v-if="[3,20,21,22].includes(officeId) && salesTypeId != 5"
                @click.stop="printInvoice()"
            >
                <v-icon small>far fa-file-pdf</v-icon>
                <span class="ml-1">{{ $t('message.invoice') }}</span>
            </v-btn>
            <v-btn
                :loading="loading.invoice"
                small
                class="ml-2 lighten-2"
                v-if="[1,3,21,22].includes(officeId) && salesTypeId == 5"
                @click.stop="printInvoice()"
            >
                <v-icon small>far fa-file-pdf</v-icon>
                <span class="ml-1">{{ $t('message.invoice') }}</span>
            </v-btn>
            <template v-if="officeId == 20 && salesTypeId == 5">
                <v-btn
                    :loading="loading.invoiceDualCurrency"
                    small
                    class="ml-2 lighten-2"
                    @click.stop="printInvoiceDualCurrency()"
                >
                    <v-icon small>far fa-file-pdf</v-icon>
                    <span class="ml-1">{{ $t('message.invoice') + ' ' + contractCurrency + '-' + invoiceCurrency  }}</span>
                </v-btn>
                <v-btn
                    :loading="loading.invoice"
                    small
                    class="ml-2 lighten-2"
                    @click.stop="printInvoice()"
                >
                    <v-icon small>far fa-file-pdf</v-icon>
                    <span class="ml-1">{{ $t('message.invoice') + ' ' + invoiceCurrency }}</span>
                </v-btn>
            </template>
            <v-btn
                :loading="loading.invoice"
                small
                class="ml-2 lighten-2"
                v-if="destinationId == 1 && salesTypeId != 5"
                @click.stop="printInvoice()"
            >
                <v-icon small>far fa-file-pdf</v-icon>
                <span class="ml-1">{{ $t('message.invoice') }}</span>
            </v-btn>
        </template>
        <template v-if="updateMode && listMode">
            <v-list-item class="font-sm" @click.stop="printInvoice()" v-if="officeId == 1 && destinationId != 1 && salesTypeId != 5">
                <v-progress-circular :size="20" :width="2" indeterminate color="red lighten-1" class="mr-2" v-if="loading.invoice"/>
                <v-icon color="red lighten-1" small class="mr-2" v-else>fas fa-file-pdf</v-icon>
                <span>{{ $t('message.pdf') }}</span>
            </v-list-item>
            <v-list-item class="font-sm" @click.stop="printInvoice()" v-if="[3,20,21,22].includes(officeId) && salesTypeId != 5">
                <v-progress-circular :size="20" :width="2" indeterminate color="red lighten-1" class="mr-2" v-if="loading.invoice"/>
                <v-icon color="red lighten-1" small class="mr-2" v-else>fas fa-file-pdf</v-icon>
                <span>{{ $t('message.pdf') }}</span>
            </v-list-item>
            <v-list-item class="font-sm" @click.stop="printInvoice()" v-if="[1,3,20,21,22].includes(officeId) && salesTypeId == 5">
                <v-progress-circular :size="20" :width="2" indeterminate color="red lighten-1" class="mr-2" v-if="loading.invoice"/>
                <v-icon color="red lighten-1" small class="mr-2" v-else>fas fa-file-pdf</v-icon>
                <span>{{ $t('message.pdf') }}</span>
            </v-list-item>
            <v-list-item class="font-sm" @click.stop="printInvoice()" v-if="destinationId == 1 && salesTypeId != 5">
                <v-progress-circular :size="20" :width="2" indeterminate color="red lighten-1" class="mr-2" v-if="loading.invoice"/>
                <v-icon color="red lighten-1" small class="mr-2" v-else>fas fa-file-pdf</v-icon>
                <span>{{ $t('message.pdf') }}</span>
            </v-list-item>
        </template>
        <PrintInvoiceBlobV3
            :dual-currency="false"
            :invoice-id="invoiceId"
            :is-tax-invoice="false"
            :key="updateInvoiceKey"
            @pdf-uploading="invoiceUploading"
            @pdf-uploaded="invoiceUploaded"
            @pdf-not-uploaded="invoiceNotUploaded"
            v-if="hasInvoice"
        />
        <PrintInvoiceBlobV3
            :dual-currency="true"
            :invoice-id="invoiceId"
            :is-tax-invoice="false"
            :key="updateInvoiceDualCurrencyKey"
            @pdf-uploading="invoiceDualCurrencyUploading"
            @pdf-uploaded="invoiceDualCurrencyUploaded"
            @pdf-not-uploaded="invoiceDualCurrencyNotUploaded"
            v-if="hasInvoiceDualCurrency"
        />
        <PrintTaxInvoiceBlobV3
            :dual-currency="false"
            :invoice-id="invoiceId"
            :is-tax-invoice="true"
            :key="updateTaxInvoiceKey"
            @pdf-uploading="taxInvoiceUploading"
            @pdf-uploaded="taxInvoiceUploaded"
            @pdf-not-uploaded="taxInvoiceNotUploaded"
            v-if="hasTaxInvoice"
        />
    </div>
</template>

<script>
import {mapFields} from "vuex-map-fields";
import {api} from "Api";
import {log, makeId} from "Helpers/helpers";
import {mapGetters} from "vuex";
const PrintInvoiceBlobV3 = () => import("Views/v1/invoices/PrintInvoiceBlobV3");
const PrintTaxInvoiceBlobV3 = () => import("Views/v1/invoices/PrintInvoiceBlobV3");

export default {
    name: "PrintDirectSalesInvoiceButton",
    props: ['invoiceId','updateMode','listMode','officeId','salesTypeId','destinationId','contractCurrencyId','invoiceCurrencyId'],
    components: {PrintInvoiceBlobV3, PrintTaxInvoiceBlobV3},
    data() {
        return {
            currentInvoicePdf: null,
            currentInvoiceDualCurrencyPdf: null,
            hasInvoice: false,
            hasInvoiceDualCurrency: false,
            hasTaxInvoice: false,
            contractCurrency: 'USD',
            loading: {
                invoice: false,
                invoiceDualCurrency: false,
                taxInvoice: false
            },
            invoiceCurrency: 'MYR',
            updateInvoiceKey: 1,
            updateInvoiceDualCurrencyKey: 1,
            updateTaxInvoiceKey: 1
        }
    },
    computed: {
        ...mapGetters('currency',{
            allCurrencies: 'allCurrencies'
        }),
        ...mapFields('runtime',{
            openedTabs: 'openedTabs'
        }),
    },
    methods: {
        getExistingPdf() {
            return new Promise((resolve, reject) => {
                api
                    .get("/invoices/" + this.invoiceId, {
                        params: {
                            fields: [
                                'invoices.location AS invoices__location',
                                'invoices.dc_location AS invoices__dc_location'
                            ]
                        }
                    })
                    .then(response => {
                        const invoice = response.data.data[0]
                        if(invoice){
                            this.currentInvoicePdf = invoice.Invoice.location
                        }
                        resolve('success')
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        invoiceNotUploaded() {
            this.loading.invoice = false
            this.hasInvoice = false
            this.$toast.error(this.$t('message.errors.couldNotPrint'),
                {
                    classes: ['icon-float-left'],
                    icon: 'error_outline'
                }
            )
        },
        invoiceDualCurrencyNotUploaded() {
            this.loading.invoiceDualCurrency = false
            this.hasInvoiceDualCurrency = false
            this.$toast.error(this.$t('message.errors.couldNotPrint'),
                {
                    classes: ['icon-float-left'],
                    icon: 'error_outline'
                }
            )
        },
        invoiceUploaded(url) {
            this.currentInvoicePdf = url
            this.loading.invoice = false
            this.viewFile(this.currentInvoicePdf)
            this.hasInvoice = false
        },
        invoiceDualCurrencyUploaded(url) {
            this.currentInvoiceDualCurrencyPdf = url
            this.loading.invoiceDualCurrency = false
            this.viewFile(this.currentInvoiceDualCurrencyPdf)
            this.hasInvoiceDualCurrency = false
        },
        invoiceUploading() {
            this.loading.invoice = true
        },
        invoiceDualCurrencyUploading() {
            this.loading.invoiceDualCurrency = true
        },
        printInvoice() {
            this.loading.invoice = true
            this.getExistingPdf()
                .then(()=>{
                    if(this.currentInvoicePdf != null){
                        this.viewFile(this.currentInvoicePdf)
                            .then(() => this.loading.invoice = false)
                            .catch(() => this.loading.invoice = false)
                    } else {
                        this.hasInvoice = true
                    }
                })
                .catch((error) => {
                    this.$toast.error(error,
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                    this.loading.invoice = false
                })
        },
        printInvoiceDualCurrency() {
            this.loading.invoiceDualCurrency = true
            this.getExistingPdf()
                .then(()=>{
                    if(this.currentInvoiceDualCurrencyPdf != null){
                        this.viewFile(this.currentInvoiceDualCurrencyPdf)
                            .then(() => this.loading.invoiceDualCurrency = false)
                            .catch(() => this.loading.invoiceDualCurrency = false)
                    } else {
                        this.hasInvoiceDualCurrency = true
                    }
                })
                .catch((error) => {
                    this.$toast.error(error,
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                    this.loading.invoiceDualCurrency = false
                })
        },
        printTaxInvoice() {
            this.loading.taxInvoice = true
            this.getExistingPdf()
                .then(()=>{
                    if(this.currentInvoicePdf != null){
                        this.viewFile(this.currentInvoicePdf)
                            .then(() => this.loading.taxInvoice = false)
                            .catch(() => this.loading.taxInvoice = false)
                    } else {
                        this.hasTaxInvoice = true
                    }
                })
                .catch((error) => {
                    this.$toast.error(error,
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                    this.loading.taxInvoice = false
                })
        },
        taxInvoiceNotUploaded() {
            this.loading.taxInvoice = false
            this.hasTaxInvoice = false
            this.$toast.error(this.$t('message.errors.couldNotPrint'),
                {
                    classes: ['icon-float-left'],
                    icon: 'error_outline'
                }
            )
        },
        taxInvoiceUploaded(url) {
            this.currentInvoicePdf = url
            this.loading.taxInvoice = false
            this.viewFile(this.currentInvoicePdf)
            this.hasTaxInvoice = false
        },
        taxInvoiceUploading() {
            this.loading.taxInvoice = true
        },
        viewFile(file) {
            return new Promise((resolve,reject) => {
                try {
                    let tab = window.open(file + '?' + makeId(5), '_blank')
                    tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                    this.openedTabs.push(tab.name)
                    this.$emit('print-completed')
                    resolve('done')
                } catch(error) {
                    reject('error')
                }
            })
        }
    },
    created(){
        const invoiceCurrency = this.allCurrencies.find(c => c.Currency.id == this.invoiceCurrencyId)
        const contractCurrency = this.allCurrencies.find(c => c.Currency.id == this.contractCurrencyId)
        if(invoiceCurrency) this.invoiceCurrency = invoiceCurrency.Currency.code
        if(contractCurrency) this.contractCurrency = contractCurrency.Currency.code
    }
}
</script>

<style scoped>

</style>